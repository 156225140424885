<script setup lang="ts">
/**
 * @author Alexander Kenchoshvili, Luka Tchigladze
 */

import { ref, watch, onMounted } from "vue";
import type { BurgerItemsInterface } from "@/types/burgerInterface";

import BurgerLine from "../SVG/actions/BurgerLine.vue";
import Dropdown from "@/components/regularComponents/UIComponents/Dropdown.vue";
import { useMainStore } from "@/store/main/index";

const props = withDefaults(
  defineProps<{
    items: BurgerItemsInterface[];
    rightSlotText?: string;
    titleActive: boolean;
  }>(),
  {
    titleActive: true,
  }
);

const route = useRoute();

const label = ref("");
const showTitle = ref(true);

const emit = defineEmits<{
  (e: "select", item: BurgerItemsInterface | undefined): void;
}>();

const mainStore = useMainStore();

const isCollapsed = ref(false);
const selectedItem = ref<BurgerItemsInterface>();

const filteredItems = ref<BurgerItemsInterface[]>([]);

const onClickOutside = () => {
  isCollapsed.value = false;
};

const onSelect = (item: BurgerItemsInterface) => {
  selectedItem.value = item;
  isCollapsed.value = false;
  showTitle.value = false;
  emit("select", selectedItem.value);
};

const setProjectDropdown = () => {
  filteredItems.value = props.items.filter((item) => item.status?.some((x) =>
    mainStore.terms?.project_status?.some(
      (status) => status.id === x && status?.slug === "current"
    )
  ) && item.id !== selectedItem.value?.id) as BurgerItemsInterface[];
};

watch(
  props,
  () => {
    const match = props.items.find((el) => el.isActive);

    if (match) {
      selectedItem.value = match;
    }
  },
  {
    immediate: true,
  }
);

watch(route, () => {
  setProjectDropdown();
});

onMounted(() => {
  setTimeout(() => {
    setProjectDropdown();
  }, 500);
});
</script>

<template>
  <div class="d-flex j-center burger-drop">
    <div class="custom-burger-dropdown" v-click-outside="onClickOutside">
      <div
        class="burgerdropdown"
        :class="{
          'burgerdropdown--active': isCollapsed,
          'is-selected': selectedItem,
          'isnot-selected': !selectedItem,
        }"
        @click="isCollapsed = !isCollapsed"
      >
        <span v-if="showTitle && titleActive" class="burgerdropdown__title p-16">{{
          label
        }}</span>

        <span
          v-if="selectedItem"
          class="burgerdropdown__choice p-16"
          :class="{
            'burgerdropdown__choice--active p-16': !isCollapsed && selectedItem,
          }"
        >
          {{ selectedItem?.title }}
        </span>

        <div class="burgerdropdown__burger-lines" :class="{ active: isCollapsed }">
          <BurgerLine class="burger-lines__top" />
          <BurgerLine class="burger-lines__middle" />
          <BurgerLine class="burger-lines__bottom" />
        </div>
      </div>

      <Dropdown :active="isCollapsed" hideIcon>
        <template #body>
          <div>
            <ul v-if="items?.length" class="list">
              <li
                v-for="item in filteredItems"
                :key="item?.id"
                @click="onSelect(item)"
                class="custom-select__item p-16"
              >
                {{ item?.title }}
              </li>
            </ul>
            <p v-else class="custom-select__item op-3 ev-none text-center p-16">
              {{ $t("list is empty") }}
            </p>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.custom-burger-dropdown {
  cursor: pointer;

  .burgerdropdown {
    min-width: 510px;
    background-color: $border-active;
    padding: 36px 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @include maxq(desktop-2k) {
      min-width: 380px;
      padding: 26px 32px;
      gap: 20px;
    }

    @include maxq(desktop-lg) {
      min-width: 240px;
      padding: 21.5px 24px;
      gap: 10px;
    }

    &__title {
      color: $white;
      line-height: 17px;
      @include weight(600);
    }

    &__burger-lines {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 42px;
      height: 20px;

      @include maxq(desktop-2k) {
        width: 32px;
      }

      @include maxq(desktop-lg) {
        gap: 1px;
        width: 10px;
        height: 10px;
      }

      .burger-lines__bottom {
        @include easeIn(250ms, all);
      }
      .burger-lines__middle {
        @include easeIn(250ms, all);
      }
      .burger-lines__top {
        @include easeIn(250ms, all);
      }

      svg {
        width: 100%;

        rect {
          width: 20px;
          height: 3px;

          @include maxq(desktop-lg) {
            width: 10px;
            height: 1px;
          }
        }
      }

      &.active {
        .burger-lines__middle {
          opacity: 0;
        }
        .burger-lines__top {
          transform: translateY(3px) rotate(45deg);
        }
        .burger-lines__bottom {
          transform: translateY(-3px) rotate(-45deg);
        }
      }
    }
    &__choice {
      color: $white;
      line-height: 34px;
      @include weight(600);

      @include maxq(desktop-lg) {
        line-height: 17px;
      }

      &--active {
        line-height: 34px;
        @include weight(600);

        @include maxq(desktop-lg) {
          line-height: 17px;
        }
      }
    }
  }
  .custom-dropdown {
    padding: 0;

    .drop-content {
      right: unset;
      left: 0;
      width: 100%;

      .drop-content-in {
        background-color: $border-active;
        padding: unset;
        padding: 20px 24px;
        min-width: unset;
        max-height: 300px;
        overflow-y: auto;
        transition: max-height 0.3s ease-out;
        scrollbar-width: thin;
        scrollbar-color: #d4d4d4 #f5f5f4;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f5f5f4;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d4d4d4;
          background-color: #afafaf;
        }
        
        &::-webkit-scrollbar-button {
          display: none;
        }

        .custom-select__item {
          color: $white;
          text-transform: capitalize;
          padding: unset;
          padding-bottom: 40px;
          padding-left: 16px;
          padding-top: 4px;
          border-left: 1px solid #7a7a7a;
          position: relative;
          @include easeIn(300ms, all);

          @include maxq(desktop-lg) {
            padding-bottom: 28px;
          }

          &::before {
            content: "";
            width: 0px;
            height: 0px;
            background-color: #fcf192;
            top: 0;
            left: -1px;
            position: absolute;
            @include easeIn(300ms, all);
          }
          @include hover {
            background-color: unset;
            color: #fcf192;
            &::before {
              width: 1px;
              // height: 31px;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
